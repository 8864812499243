export default {
  bigStat: [
    {
      product: "Light Blue",
      total: "4,232",
      color: "primary",
      registrations: {
        value: 830,
        profit: true
      },
      bounce: {
        value: 4.5,
        profit: false
      }
    },
    {
      product: "Sing App",
      total: "754",
      color: "danger",
      registrations: {
        value: 30,
        profit: true
      },
      bounce: {
        value: 2.5,
        profit: true
      }
    },
    {
      product: "RNS",
      total: "1,025",
      color: "info",
      registrations: {
        value: 230,
        profit: true
      },
      bounce: {
        value: 21.5,
        profit: false
      }
    }
  ],
  table: [
    {
      id: 0,
      name: "Mark Otto",
      email: "ottoto@wxample.com",
      product: "ON the Road",
      price: "$25 224.2",
      date: "11 May 2017",
      city: "Otsego",
      status: "Sent"
    },
    {
      id: 1,
      name: "Jacob Thornton",
      email: "thornton@wxample.com",
      product: "HP Core i7",
      price: "$1 254.2",
      date: "4 Jun 2017",
      city: "Fivepointville",
      status: "Sent"
    },
    {
      id: 2,
      name: "Larry the Bird",
      email: "bird@wxample.com",
      product: "Air Pro",
      price: "$1 570.0",
      date: "27 Aug 2017",
      city: "Leadville North",
      status: "Pending"
    },
    {
      id: 3,
      name: "Joseph May",
      email: "josephmay@wxample.com",
      product: "Version Control",
      price: "$5 224.5",
      date: "19 Feb 2018",
      city: "Seaforth",
      status: "Declined"
    },
    {
      id: 4,
      name: "Peter Horadnia",
      email: "horadnia@wxample.com",
      product: "Let's Dance",
      price: "$43 594.7",
      date: "1 Mar 2018",
      city: "Hanoverton",
      status: "Sent"
    }
  ]
};
