<template>
  <div class="dashboard-page">
    <h1 class="page-title">Dashboard</h1>
    <div class="row">
      <div class="col-6">
        <fieldset>
          <legend>Jurídicos</legend>
          <Widget class="h-100 mb-3" title="Audiencias">
            <apexchart
              width="500"
              type="donut"
              :options="chartOptionsAudiences"
              :series="audiences"
            ></apexchart>
          </Widget>
          <Widget class="h-100 mb-3" title="Procesos">
            <apexchart
              width="500"
              type="donut"
              :options="chartOptionsProcess"
              :series="processLawyer"
            ></apexchart>
          </Widget>
        </fieldset>
      </div>
    </div>
    <modal-list-process :records="records" :title="title"></modal-list-process>
    <modal-list-audiences
      :records="records"
      :title="title"
    ></modal-list-audiences>
  </div>
</template>

<script>
import Widget from "@/components/Widget/Widget";
import { deserialize } from "jsonapi-fractal";
import { mapState } from "vuex";
import ModalListAudiences from "./ModalListAudiences";
import ModalListProcess from "./ModalListProcess";
export default {
  name: "Dashboard",

  inject: [
    "expiredTracesProcessRepository",
    "audiencesExpiredLawyerRepository",
    "audiencesNextLawyerRepository",
    "audiencesTodayLawyerRepository"
  ],

  components: {
    Widget,
    ModalListProcess,
    ModalListAudiences
  },

  computed: {
    ...mapState("user", ["user"])
  },

  data() {
    const me = this;
    return {
      title: "",
      lawyer_id: null,
      records: [],
      audiences: [],
      processLawyer: [],
      audiencesNext: [],
      audiencesToday: [],
      audiencesExpired: [],
      processLawyerPresented: [],
      ProcessLawyerNotified: [],
      processLawyerAdmitted: [],
      processLawyerNoAdmitted: [],
      processLawyerNextToDefeat: [],
      processLawyerDefeated: [],
      processLawyerClosed: [],
      chartOptionsProcess: {
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              me.openModalProcess(config.dataPointIndex);
            }
          }
        },
        labels: [
          "Procesos presentados",
          "Procesos notificados",
          "Procesos admitidos",
          "Procesos no admitidos",
          "Procesos próximos a vencer",
          "Procesos vencidos",
          "Procesos cerrados"
        ],
        colors: [
          "#008FFB",
          "#4CAF50",
          "#662E9B",
          "#546E7A",
          "#F86624",
          "#D7263D",
          "#FF4560"
        ],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: "Total",
                  fontSize: "22px",
                  fontWeight: 900,
                  color: "#373d3f",
                  formatter: function(w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        dataLabels: {
          formatter: (val, { seriesIndex, dataPointIndex, w }) => {
            return w.config.series[seriesIndex];
          },
          textAnchor: "middle",
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45
            }
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45
          }
        },
        fill: {
          colors: [
            "#008FFB",
            "#4CAF50",
            "#662E9B",
            "#546E7A",
            "#F86624",
            "#D7263D",
            "#FF4560"
          ]
        }
      },
      chartOptionsAudiences: {
        chart: {
          events: {
            dataPointSelection: (event, chartContext, config) => {
              me.openModalAudiences(config.dataPointIndex);
            }
          }
        },
        labels: [
          "Audiencias vencidas",
          "Audiencias para hoy",
          "Audiencias próximas"
        ],
        colors: ["#FA4443", "#4CAF50", "#FEB019"],
        plotOptions: {
          pie: {
            donut: {
              labels: {
                show: true,
                total: {
                  show: true,
                  showAlways: true,
                  label: "Total",
                  fontSize: "22px",
                  fontWeight: 900,
                  color: "#373d3f",
                  formatter: function(w) {
                    return w.globals.seriesTotals.reduce((a, b) => {
                      return a + b;
                    }, 0);
                  }
                }
              }
            }
          }
        },
        dataLabels: {
          formatter: (val, { seriesIndex, dataPointIndex, w }) => {
            return w.config.series[seriesIndex];
          },
          textAnchor: "middle",
          distributed: false,
          offsetX: 0,
          offsetY: 0,
          style: {
            fontSize: "14px",
            fontFamily: "Helvetica, Arial, sans-serif",
            fontWeight: "bold",
            colors: undefined
          },
          background: {
            enabled: true,
            foreColor: "#fff",
            padding: 4,
            borderRadius: 2,
            borderWidth: 1,
            borderColor: "#fff",
            opacity: 0.9,
            dropShadow: {
              enabled: false,
              top: 1,
              left: 1,
              blur: 1,
              color: "#000",
              opacity: 0.45
            }
          },
          dropShadow: {
            enabled: false,
            top: 1,
            left: 1,
            blur: 1,
            color: "#000",
            opacity: 0.45
          }
        },
        fill: {
          colors: ["#FA4443", "#4CAF50", "#FEB019"]
        }
      }
    };
  },
  methods: {
    async handleProcessLawyerPresented() {
      const me = this;
      const stateId = "58189f89-7c7b-481c-ab96-f7bc37dc1dd8";

      let result = await me.expiredTracesProcessRepository.get(
        stateId,
        me.lawyer_id
      );

      me.processLawyerPresented = deserialize(result.data);
      me.processLawyer.push(me.processLawyerPresented.length);
    },
    async handleProcessLawyerNotified() {
      const me = this;
      const stateId = "2b37c417-1e39-4160-835d-176a1619233a";

      let result = await me.expiredTracesProcessRepository.get(
        stateId,
        me.lawyer_id
      );

      me.ProcessLawyerNotified = deserialize(result.data);
      me.processLawyer.push(me.ProcessLawyerNotified.length);
    },
    async handleProcessLawyerAdmitted() {
      const me = this;
      const stateId = "dbd39767-4a80-47bb-92eb-0eea30765933";

      let result = await me.expiredTracesProcessRepository.get(
        stateId,
        me.lawyer_id
      );

      me.processLawyerAdmitted = deserialize(result.data);
      me.processLawyer.push(me.processLawyerAdmitted.length);
    },
    async handleProcessLawyerNoAdmitted() {
      const me = this;
      const stateId = "5e4be4eb-ca6a-4052-8f3d-7928a890e99c";

      let result = await me.expiredTracesProcessRepository.get(
        stateId,
        me.lawyer_id
      );

      me.processLawyerNoAdmitted = deserialize(result.data);
      me.processLawyer.push(me.processLawyerNoAdmitted.length);
    },
    async handleProcessLawyerNextToDefeat() {
      const me = this;
      const stateId = "be6ab2a6-f1ec-4464-8e67-09448c88176c";

      let result = await me.expiredTracesProcessRepository.get(
        stateId,
        me.lawyer_id
      );

      me.processLawyerNextToDefeat = deserialize(result.data);
      me.processLawyer.push(me.processLawyerNextToDefeat.length);
    },
    async handleProcessLawyerDefeated() {
      const me = this;
      const stateId = "3b278600-f1bc-471b-a6a7-8208483206c2";

      let result = await me.expiredTracesProcessRepository.get(
        stateId,
        me.lawyer_id
      );

      me.processLawyerDefeated = deserialize(result.data);
      me.processLawyer.push(me.processLawyerDefeated.length);
    },
    async handleProcessLawyerClosed() {
      const me = this;
      const stateId = "7290baaf-05bc-48ef-a492-b9dc0f015599";

      let result = await me.expiredTracesProcessRepository.get(
        stateId,
        me.lawyer_id
      );

      me.processLawyerClosed = deserialize(result.data);
      me.processLawyer.push(me.processLawyerClosed.length);
    },
    async handleAudiencesExpired() {
      const me = this;

      let result = await me.audiencesExpiredLawyerRepository.get(me.lawyer_id);

      me.audiencesExpired = deserialize(result.data);
      me.audiences.push(me.audiencesExpired.length);
    },
    async handleAudiencesNext() {
      const me = this;

      let result = await me.audiencesNextLawyerRepository.get(me.lawyer_id);

      me.audiencesNext = deserialize(result.data);
      me.audiences.push(me.audiencesNext.length);
    },
    async handleAudiencesToday() {
      const me = this;

      let result = await me.audiencesTodayLawyerRepository.get(me.lawyer_id);

      me.audiencesToday = deserialize(result.data);
      me.audiences.push(me.audiencesToday.length);
    },
    openModalProcess(process) {
      const me = this;

      if (process == 0) {
        me.records = me.processLawyerPresented;
        me.title = "Procesos presentados";
      } else if (process == 1) {
        me.records = me.ProcessLawyerNotified;
        me.title = "Procesos notificados";
      } else if (process == 2) {
        me.records = me.processLawyerAdmitted;
        me.title = "Procesos admitidos";
      } else if (process == 3) {
        me.records = me.processLawyerNoAdmitted;
        me.title = "Procesos no admitidos";
      } else if (process == 4) {
        me.records = me.processLawyerNextToDefeat;
        me.title = "Procesos próximos a vender";
      } else if (process == 5) {
        me.records = me.processLawyerDefeated;
        me.title = "Procesos vencidos";
      } else {
        me.records = me.processLawyerClosed;
        me.title = "Procesos cerrados";
      }
      me.$bvModal.show("modal-list-process");
    },
    openModalAudiences(audience) {
      const me = this;

      if (audience == 0) {
        me.records = me.audiencesExpired;
        me.title = "Audiencias vencidas";
      } else if (audience == 1) {
        me.records = me.audiencesToday;
        me.title = "Audiencias para hoy";
      } else {
        me.records = me.audiencesNext;
        me.title = "Audiencias próximas";
      }
      me.$bvModal.show("modal-list-audiences");
    }
  },

  created() {
    const me = this;

    if (me.user.lawyer !== null) {
      me.lawyer_id = me.user.lawyer.id;
    }

    me.handleAudiencesExpired();
    me.handleAudiencesToday();
    me.handleAudiencesNext();
    me.handleProcessLawyerPresented();
    me.handleProcessLawyerNotified();
    me.handleProcessLawyerAdmitted();
    me.handleProcessLawyerNoAdmitted();
    me.handleProcessLawyerNextToDefeat();
    me.handleProcessLawyerDefeated();
    me.handleProcessLawyerClosed();
  }
};
</script>

<style src="./Dashboard.scss" lang="scss" />
