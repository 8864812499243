<template>
  <b-modal
    id="modal-list-loans"
    :title="title"
    size="lg"
    :no-close-on-backdrop="true"
    body-class="position-static"
  >
    <div>
      <v-client-table
        ref="table"
        :data="records"
        :columns="columns"
        :options="localOptions"
      >
        <div
          slot="actions"
          slot-scope="{ row }"
          class="flex items-center space-x-2"
        >
          <b-button
            size="sm"
            variant="outline-primary"
            title="Ver mas"
            @click="handleLoan(row)"
          >
            <i
              class="glyphicon glyphicon-eye-open transform -translate-y-1"
            ></i>
            Ver mas
          </b-button>
        </div>
      </v-client-table>
    </div>
    <template #modal-footer>
      <b-button size="sm" variant="outline-inverse" @click="closeModal">
        Cancelar
      </b-button>
    </template>
  </b-modal>
</template>

<script>
import { formatDate } from "@/utils/utils";
export default {
  props: ["title", "records"],

  computed: {
    columns() {
      let cols = this.localOptions.columns.map(col => {
        return col.prop;
      });

      cols.push("actions");
      return cols;
    },

    headings() {
      let heads = {};

      this.localOptions.columns.forEach(col => {
        heads[col.prop] = col.header;
      });

      heads.actions = "";
      return heads;
    }
  },

  data() {
    const me = this;
    return {
      localOptions: {
        columns: [
          {
            header: "Cliente",
            prop: "client.full_name"
          },
          {
            header: "Télefono",
            prop: "client.phone"
          },
          {
            header: "Capital",
            prop: "capital"
          },
          {
            header: "Fecha de vencimiento",
            prop: "finish_date"
          }
        ],
        primary: "id",
        sortable: ["client.full_name", "capital"],
        templates: {
          capital(h, row) {
            return `$${me.$options.filters.numericFormat(row.capital)}`;
          },
          finish_date(h, row) {
            return formatDate(row.finish_date);
          }
        }
      },
      selectedRow: null
    };
  },

  methods: {
    closeModal() {
      this.$bvModal.hide("modal-list-loans");
    },

    formatDate(date) {
      return formatDate(date);
    },

    handleLoan(row) {
      this.$router.push({
        name: "Loans",
        params: {
          clientId: row.client_id
        }
      });
    }
  },

  mounted() {
    const me = this;

    me.localOptions = {
      ...me.localOptions,
      ...{
        headings: me.headings
      }
    };
  }
};
</script>

<style lang="scss" scoped></style>
